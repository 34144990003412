<template>
  <!-- <v-container id="dashboard" fluid tag="section"> -->
  <v-row>
    <v-col cols="12">
      <base-material-card color="success" inline title="Tools" class="px-5 py-3">
        <v-row dense class="pt-3 pb-4">
          <v-col cols="12">
            <div class="text-h4">{{ $t("Update all scopus:") }}</div>
          </v-col>
          <v-col cols="12">
            <v-btn color="primary" class="ml-4 mt-4" @click="updateData('scopus', 'all')">{{ $t("All") }}</v-btn>
            <v-btn color="primary" class="ml-4 mt-4" @click="updateData('scopus', 'details')">
              {{ $t("Details") }}
            </v-btn>
            <v-btn color="primary" class="ml-4 mt-4" @click="updateData('scopus', 'publications')">
              {{ $t("Publications") }}
            </v-btn>
            <v-btn color="primary" class="ml-4 mt-4" @click="updateData('scopus', 'citations')">
              {{ $t("Citations") }}
            </v-btn>
          </v-col>
          <v-col cols="12">
            <div class="text-h4">{{ $t("Run all scopus events:") }}</div>
          </v-col>
          <v-col cols="12">
            <v-btn color="primary" class="ml-4 mt-4" @click="runEvents('scopus', 'all')">{{ $t("All") }}</v-btn>
            <v-btn color="primary" class="ml-4 mt-4" @click="runEvents('scopus', 'details')">
              {{ $t("Details") }}
            </v-btn>
            <v-btn color="primary" class="ml-4 mt-4" @click="runEvents('scopus', 'publications')">
              {{ $t("Publications") }}
            </v-btn>
            <v-btn color="primary" class="ml-4 mt-4" @click="runEvents('scopus', 'citations')">
              {{ $t("Citations") }}
            </v-btn>
          </v-col>
          <v-col cols="12" class="mt-4">
            <div class="text-h4">{{ $t("Update all publons:") }}</div>
          </v-col>
          <v-col cols="12">
            <v-btn color="primary" class="ml-4 mt-4" @click="updateData('publons', 'all')">{{ $t("All") }}</v-btn>
            <v-btn color="primary" class="ml-4 mt-4" @click="updateData('publons', 'details')">
              {{ $t("Details") }}
            </v-btn>
            <v-btn color="primary" class="ml-4 mt-4" @click="updateData('publons', 'publications')">
              {{ $t("Publications") }}
            </v-btn>
            <v-btn color="primary" class="ml-4 mt-4" @click="updateData('publons', 'editorial_boards')">
              {{ $t("Editorials") }}
            </v-btn>
            <v-btn color="primary" class="ml-4 mt-4" @click="updateData('publons', 'reviews')">
              {{ $t("Reviews") }}
            </v-btn>
          </v-col>
          <v-col cols="12" class="mt-4">
            <div class="text-h4">{{ $t("Run all publons events:") }}</div>
          </v-col>
          <v-col cols="12">
            <v-btn color="primary" class="ml-4 mt-4" @click="runEvents('publons', 'all')">{{ $t("All") }}</v-btn>
            <v-btn color="primary" class="ml-4 mt-4" @click="runEvents('publons', 'details')">
              {{ $t("Details") }}
            </v-btn>
            <v-btn color="primary" class="ml-4 mt-4" @click="runEvents('publons', 'publications')">
              {{ $t("Publications") }}
            </v-btn>
            <v-btn color="primary" class="ml-4 mt-4" @click="runEvents('publons', 'editorial_boards')">
              {{ $t("Editorials") }}
            </v-btn>
            <v-btn color="primary" class="ml-4 mt-4" @click="runEvents('publons', 'reviews')">
              {{ $t("Reviews") }}
            </v-btn>
          </v-col>
        </v-row>
      </base-material-card>
    </v-col>
  </v-row>
  <!-- </v-container> -->
</template>
<script>
import axios from "../../../../plugins/axios"
import { EventBus } from "../../../../EventBus"
export default {
  name: "Home",
  data() {
    return {
      year: 2020,
      type: "if",
      importing: false,
      fileLoaded: false,
      dense: true,
      hided: false,
      records: [],
      index: -1,
      subindex: 0,
      record: {}
    }
  },
  computed: {
    inputValidate() {
      const rule = []
      rule.push(v => !!v || this.$t("required"))
      return rule
    }
  },
  created() {},
  methods: {
    updateData(col, type) {
      axios.post("infuni/events/academics/" + col + "/all/" + type).finally(() => {
        EventBus.$emit("show-alert", {
          message: "Event send.",
          timeout: 7000
        })
      })
    },
    runEvents(col, type) {
      axios.post("infuni/events/run/academics." + col + "." + type).finally(() => {
        EventBus.$emit("show-alert", {
          message: "Run event send.",
          timeout: 7000
        })
      })
    }
  }
}
</script>
<style></style>
